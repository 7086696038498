import { Controller } from 'stimulus'
import { googleAnalytics } from 'lib/googleAnalytics'

export default class extends Controller {
  connect () {
    this.logPageView()
    // Changing this "analyticsProcessed" data attribute causes the connect() to fire on each turbo page load
    this.element.dataset.analyticsProcessed = 'true'
  }

  logPageView () {
    // We only want to send certain query strings to Google Analytics (not the entire location.search string).
    // Right now we only want the ?page=X values to go through to GA:
    const allowedSearchParams = ['page']

    // This loops over the available search params and builds a new list based on the "allowedSearchParams" above
    const browserSearchParams = new URLSearchParams(window.location.search)
    const searchParamsToLog = new URLSearchParams()
    for (const searchParam of browserSearchParams.keys()) {
      if (allowedSearchParams.includes(searchParam)) {
        searchParamsToLog.append(searchParam, browserSearchParams.get(searchParam))
      }
    }

    // We then stringify any allowed search params and append that to our GA page
    const searchStringToLog = searchParamsToLog.toString()
    const pagePath = `${window.location.pathname}${searchStringToLog.length > 0 ? `?${searchStringToLog}` : ''}`

    googleAnalytics('config', this.data.get('trackingId'), { page_path: pagePath })
  }
}
