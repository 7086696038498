import { Controller } from 'stimulus'
import { googleAnalyticsEvent } from 'lib/googleAnalytics'
import { getItemDownloadPath } from 'lib/linkHelpers'
import { handleError } from 'lib/errors'

export default class extends Controller {
  static values = {
    humaneId: String,
    itemType: String,
    operation: String,
    loading: Boolean,
    source: String
  }

  static targets = ['modalTemplate']

  getGlobalMessageController () {
    return this.application.controllers.find(controller => controller.context.identifier === 'global--message')
  }

  getDownloadModalController () {
    return this.application.controllers.find(controller => controller.context.identifier === 'download--modal-template')
  }

  disableButton () {
    // We set a loading state so we can show a loading icon / animation on the button using css
    this.loadingValue = true
    // Disable the button so the user cannot click it multiple times
    this.element.setAttribute('disabled', true)
  }

  enableButton () {
    this.loadingValue = false
    this.element.removeAttribute('disabled')
  }

  async click (event) {
    event.preventDefault()
    this.disableButton()

    googleAnalyticsEvent({
      // e.g. "download icon" or "copy icon" or "download illustration"
      eventAction: `${this.operationValue} ${this.itemTypeValue}`,
      eventCategory: 'Downloads',
      eventLabel: `humane_id: ${this.humaneIdValue}, source: ${this.sourceValue}`
    })

    if (this.operationValue.match(/download/)) {
      // Display a popup message to the user letting them know their download is starting:
      const messageController = this.getGlobalMessageController()
      messageController.showMessage('Download will commence shortly')
    }

    try {
      const response = await fetch(getItemDownloadPath({ itemType: this.itemTypeValue, humaneId: this.humaneIdValue, operation: this.operationValue }))
      const downloadData = await response.json()
      if (!downloadData || !downloadData.result || downloadData.result !== 'success') {
        throw new Error('Download button controller: Failed parsing JSON response from API')
      }
      if (this.operationValue.match(/download/)) {
        const assetUrl = downloadData.download_asset_url
        await this.handleItemDownload(assetUrl)
        this.getDownloadModalController().openModal()
      } else if (this.operationValue === 'copy') {
        const assetUrl = downloadData.optimized_asset_url
        await this.handleItemCopy(assetUrl)
      }
    } catch (error) {
      // Display a popup message to the user letting them know something didn't work right :(
      const messageController = this.getGlobalMessageController()
      messageController.showMessage(`Error: Failed to ${this.operationValue} this item`)
      // Log this to rollbar so we can investigate:
      handleError(error)
    }

    // Don't enable the button straight away, lets give our funky animation a little bit of time to run.
    setTimeout(() => { this.enableButton() }, 500)
    return false
  }

  async handleItemDownload (assetUrl) {
    // Open this asset with the "download" attribute set
    // See: https://stackoverflow.com/a/43523297
    const link = document.createElement('a')
    link.href = assetUrl
    link.download = assetUrl.substr(assetUrl.lastIndexOf('/') + 1)
    link.click()
  }

  async handleItemCopy (assetUrl) {
    try {
      const response = await fetch(assetUrl)
      const svgCode = await response.text()
      await navigator.clipboard.writeText(svgCode)
      // Let the user know we've copied to their clipboard:
      const messageController = this.getGlobalMessageController()
      messageController.showMessage('SVG code copied to your clipboard')
    } catch (error) {
      console.error(error)
      throw new Error('Failed to copy SVG to clipboard')
    }
  }
}
