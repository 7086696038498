import { Controller } from 'stimulus'
import { tns } from 'tiny-slider'

export default class extends Controller {
  static targets = ['track', 'slideItem']
  static classes = ['initialized']
  static values = {
    mobileColumnCount: { type: Number, default: 2 }
  }

  connect () {
    // We don't want to do this expensive work on the turbo in-memory cached version.
    // We wait a few more milliseconds for the network request to load, then we do the real initialization.
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    this.initializeSlider()
  }

  initializeSlider () {
    this.slider = tns({
      container: this.trackTarget,
      loop: true,
      touch: true,
      mouseDrag: false,
      controls: false,
      center: false,
      nav: false,
      slideBy: 'page',
      items: this.mobileColumnCountValue,
      responsive: {
        420: {
          items: 2
        },
        700: {
          items: 3
        },
        1000: {
          items: 4
        }
      }
    })

    if (this.slider) {
      this.element.classList.add(this.initializedClass)
    }
  }

  nextSlide () {
    this.slider && this.slider.goTo('next')
  }

  prevSlide () {
    this.slider && this.slider.goTo('prev')
  }
}
