import { Controller } from 'stimulus'

const reshotNavInteractionLocalStorageKey = 'reshot-nav-interaction'

export default class extends Controller {
  static values = {
    firstLoad: Boolean
  }

  connect () {
    this.firstLoadValue = !window.localStorage.getItem(reshotNavInteractionLocalStorageKey)
  }

  onScroll () {
    if (!this.onScrollRunning) {
      this.onScrollRunning = true
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(this.setHeaderOpacity.bind(this))
      } else {
        setTimeout(this.setHeaderOpacity.bind(this), 66)
      }
    }
  }

  setHeaderOpacity () {
    this.element.setAttribute('data-scrolled', window.scrollY > 10)
    this.onScrollRunning = false
  }

  interactedWithNav () {
    window.localStorage.setItem(reshotNavInteractionLocalStorageKey, 'true')

    setTimeout(() => {
      this.firstLoadValue = false
    }, 200)
  }
}
