import { Controller } from 'stimulus'
import { googleAnalyticsEvent } from 'lib/googleAnalytics'

export default class extends Controller {
  static values = {
    campaign: String,
    uri: String
  }

  logClickEvent () {
    googleAnalyticsEvent({
      eventAction: 'Elements Click',
      eventCategory: this.campaignValue,
      eventLabel: this.uriValue
    })
  }
}
