const googleAnalytics = (...args) => {
  // We don't want to send any analytics events during the turbo in memory cache loading time.
  // We wait a few more milliseconds for the network request to load, then we allow events to fire.
  if (document.documentElement.hasAttribute('data-turbo-preview')) return

  if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
    window.gtag(...args)
  }
}

const googleAnalyticsEvent = ({ eventAction, eventCategory, eventLabel, eventValue = null, nonInteraction = false, callback = null }) => {
  // Firefox and ad blockers will prevent gtag from running at all.
  // So we register a callback that is fired after 500ms in case gtag doesn't run at all.
  const eventCallbackFallback = callback ? setTimeout(callback, 500) : null

  // Push the GA event into Google Tag Manager:
  googleAnalytics(
    'event',
    eventAction,
    {
      event_category: eventCategory,
      event_label: eventLabel,
      value: eventValue,
      non_interaction: nonInteraction,
      event_callback: (containerId) => {
        // This callback is fired for every "tag" that is registered in Google Tag Manager.
        // e.g. once for Analytics, and once for Optimize.
        // We have to check the container ID starts with "UA-" to know it's a Google Analytics callback.
        if (containerId.startsWith('UA-')) {
          // This fires when the GA event has successfully been recorded.
          // We remove our fallback timer and call the callback function if one exists
          clearTimeout(eventCallbackFallback)
          callback && callback()
        }
      }
    }
  )
}

export { googleAnalytics, googleAnalyticsEvent }
