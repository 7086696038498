import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['image']
  static values = {
    startOffset: Number
  }

  connect () {
    // We don't want to do this expensive work on the turbo in-memory cached preview (the first time connect() runs).
    // We wait a few more milliseconds for the network request to load before doing this (the second time connect() runs).
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    this.currentIndex = 0
    this.imageCount = this.imageTargets.length
    setTimeout(() => {
      this.startAnimation()
    }, this.startOffsetValue)
  }

  startAnimation () {
    setInterval(() => {
      this.animate()
    }, 3000)
  }

  animate () {
    let nextIndex = this.currentIndex + 1
    if (nextIndex >= this.imageCount) { nextIndex = 0 }
    let prevIndex = this.currentIndex - 1
    if (prevIndex < 0) { prevIndex = this.imageCount - 1 }

    const prevSlide = this.imageTargets[prevIndex]
    const currentSlide = this.imageTargets[this.currentIndex]
    const nextSlide = this.imageTargets[nextIndex]

    prevSlide.removeAttribute('data-prev')
    prevSlide.removeAttribute('data-fade')

    // Fade out the current slide:
    currentSlide.setAttribute('data-fade', 'true')
    currentSlide.setAttribute('data-prev', 'true')
    currentSlide.removeAttribute('data-next')

    // Set z-index on next slide so it can fade in nicely:
    nextSlide.setAttribute('data-next', 'true')

    // Bump for next iteration
    this.currentIndex = nextIndex
  }
}
