import { Controller } from 'stimulus'
import { googleAnalytics } from 'lib/googleAnalytics'

export default class extends Controller {
  setExperimentLoading ({ experiment }) {
    this.element.setAttribute(`data-experiment-${experiment.label}`, 'loading')
  }

  setExperimentVariant ({ experiment, variant }) {
    // Load the variant configuration into data attributes on the <div> tag, like this:
    //  data-experiment-tag-search-ad-placement="loaded"
    //  data-experiment-tag-search-ad-placement-id="2"
    //  data-experiment-tag-search-ad-placement-position="grid"
    this.element.setAttribute(`data-experiment-${experiment.label}`, 'loaded')
    for (const [key, value] of Object.entries(variant)) {
      this.element.setAttribute(`data-experiment-${experiment.label}-${key}`, value)
    }

    // Run any hooks from custom experiment javascript files
    if (this.element.experimentHooks) {
      this.element.experimentHooks.forEach(hook => {
        if (typeof hook === 'function') {
          hook({ experimentWrapper: this.element, experiment, variant })
        }
      })
    }
  }

  connect () {
    // We don't want to do this expensive work on the turbo in-memory cached preview (the first time connect() runs).
    // We wait a few more milliseconds for the network request to load before doing this (the second time connect() runs).
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    // Grab experiment configuration from main <div> tag, looks like this:
    // {"label":"tag-search-ad-position","id":"qWKMoLMLTOaBkgdrz3XO7Q","utm":6,"variants":[{"id":0,"position":"below"},{"id":1,"position":"above"},{"id":2,"position":"grid"}]}
    const experiment = JSON.parse(this.data.get('experiment'))

    // Set the experiment loading state early so we can start to control things with css
    this.setExperimentLoading({ experiment })

    // Sometimes optimize can be blocked from ad blocker, this is the key we use in localStorage to check for this:
    const optimizeExperimentFailedToLoadKey = `experiment_${experiment.label}_failed`

    // Fallback variant is the first one:
    const fallbackVariant = experiment.variants.find(variant => variant.id === 0)

    // Set a timeout if Google optimize fails to load. After 2 seconds we'll use the fallback variant.
    const timeout = setTimeout(() => {
      this.setExperimentVariant({ experiment, variant: fallbackVariant })
      window.localStorage.setItem(optimizeExperimentFailedToLoadKey, true)
    }, 2000)

    // Handle case where optimize has previously failed to load
    const hasExperimentFailedPreviously = window.localStorage.getItem(optimizeExperimentFailedToLoadKey)
    if (hasExperimentFailedPreviously) {
      // Optimize has previously failed to load for this user in this experiment.
      // This likely means their Ad blocker is preventing the call to optimize
      // We resolve quickly in this case so the user doesn't have to wait the 3 second time out from above
      clearTimeout(timeout)
      this.setExperimentVariant({ experiment, variant: fallbackVariant })
      // We don't return here and continue running the gtag() call below, just incase Google Optimize kicks in for the user again
      // If Google Optimize does start to work again then the "failed" flag will be cleared
    }

    googleAnalytics('event', 'optimize.callback', {
      name: experiment.id,
      callback: usersCurrentVariantId => {
      // We have received a successful variant back from Google Optimize!

        if (hasExperimentFailedPreviously) {
        // We remove this localstorage flag so we know to wait for optimize again on future runs for this user
          window.localStorage.removeItem(optimizeExperimentFailedToLoadKey)
        }

        // Clear our fallback timer so the default variant is not displayed after a few seconds
        clearTimeout(timeout)

        // Handle case where dev has manually set a variant with a query string like ?set_e6=2
        if (window.location.search.length > 0 && typeof URLSearchParams !== 'undefined') {
          const debugUrlParams = new URLSearchParams(window.location.search)
          const customExperimentVariant = debugUrlParams.get(`set_${experiment.label}`)
          if (customExperimentVariant !== null) {
            usersCurrentVariantId = customExperimentVariant
          }
        }

        // Find the variant configuration from the list of available variants:
        const variantConfiguration = experiment.variants.find(variant => variant.id === parseInt(usersCurrentVariantId, 10))

        // Set the found variant configuration (or fallback if not found):
        if (variantConfiguration) {
          this.setExperimentVariant({ experiment, variant: variantConfiguration })
        } else {
          this.setExperimentVariant({ experiment, variant: fallbackVariant })
        }
      }
    })
  }
}
