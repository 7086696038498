import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['template']

  getGlobalModalController () {
    return this.application.controllers.find(controller => controller.context.identifier === 'modal')
  }

  openModal () {
    this.getGlobalModalController().setContentNode(this.element.content)
    this.getGlobalModalController().showModal()
  }
}
