// This converts raw user search text <input> into a string we can safely put in the URL slug
const userTextInputToUrlSlug = (textInput) => {
  if (!textInput) return ''
  textInput = textInput.toLowerCase()
  textInput = textInput.replace(/[^a-z0-9À-ÿ]/g, ' ') // remove any non alpha characters
  textInput = textInput.replace(/\s+/g, '-') // replace 1 or more SPACE in a row with a single -
  textInput = textInput.replace(/^-/, '') // remove any + from start of string
  textInput = textInput.replace(/-$/g, '') // removing any + from end of string
  return textInput
}

// This returns the root item type path for the supplied item type
const getItemTypePath = ({ itemType }) => {
  if (['photos', 'photo'].includes(itemType)) {
    return '/free-stock-photos/'
  }
  if (['icons', 'icon', 'icon_pack'].includes(itemType)) {
    return '/free-svg-icons/'
  }
  if (['illustrations', 'illustration'].includes(itemType)) {
    return '/free-vector-illustrations/'
  }
  return '/'
}

const getItemSearchPath = ({ filters, searchTermUrlSlug } = {}) => {
  const { itemType, ...otherFilterValues } = filters
  // The search path for photos is /free-stock-photos/search/TERM/
  // The search path for our other item types is at a level up /free-svg-icons/TERM/
  const searchSlug = itemType === 'photos' ? 'search/' : ''
  let searchUrl = `${getItemTypePath({ itemType })}${searchSlug}`
  if (searchTermUrlSlug) {
    searchUrl = `${searchUrl}${encodeURIComponent(searchTermUrlSlug)}/`
  }
  if (otherFilterValues) {
    const filterUriParams = []
    // todo: restrict filters based on item type
    for (const filterKey in otherFilterValues) {
      filterUriParams.push({
        key: filterKey,
        value: otherFilterValues[filterKey]
      })
    }
    const searchQueryString = filterUriParams.map(param => `${param.key}=${encodeURIComponent(param.value)}`).join('&')
    searchUrl = searchUrl + (searchQueryString ? `?${searchQueryString}` : '')
  }
  return searchUrl
}

// Helps us get the download path for icons and illustrations
const getItemDownloadPath = ({ itemType, humaneId, operation }) => {
  return `${getItemTypePath({ itemType })}download/${humaneId}/?operation=${operation}`
}

export { userTextInputToUrlSlug, getItemTypePath, getItemSearchPath, getItemDownloadPath }
