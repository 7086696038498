import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['image']

  getAverageColor () {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const width = canvas.width = this.imageTarget.naturalWidth
    const height = canvas.height = this.imageTarget.naturalHeight

    ctx.drawImage(this.imageTarget, 0, 0)

    const imageData = ctx.getImageData(0, 0, width, height)
    const data = imageData.data
    let r = 0
    let g = 0
    let b = 0

    for (let i = 0, l = data.length; i < l; i += 4) {
      r += data[i]
      g += data[i + 1]
      b += data[i + 2]
    }

    r = Math.floor(r / (data.length / 4))
    g = Math.floor(g / (data.length / 4))
    b = Math.floor(b / (data.length / 4))

    return { r, g, b }
  }

  imageLoad () {
    const { r, g, b } = this.getAverageColor()
    this.element.style.backgroundColor = `rgb(${r}, ${g}, ${b})`
  }
}
