import { Controller } from 'stimulus'
import { googleAnalyticsEvent } from 'lib/googleAnalytics'

export default class extends Controller {
  static values = {
    action: String,
    category: String,
    label: String,
    value: String,
    nonInteraction: Boolean
  }

  connect () {
    googleAnalyticsEvent({
      eventAction: this.actionValue,
      eventCategory: this.categoryValue,
      eventLabel: this.labelValue,
      eventValue: this.valueValue,
      nonInteraction: this.nonInteractionValue
    })
  }
}
