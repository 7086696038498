import { Controller } from 'stimulus'
import MiniMasonry from 'minimasonry'

export default class extends Controller {
  static values = {
    hasMasonry: Boolean,
    scrollbarFix: Boolean
  }

  connect () {
    // We don't want to do this expensive work on the turbo in-memory cached preview (the first time connect() runs).
    // We wait a few more milliseconds for the network request to load before doing this (the second time connect() runs).
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    this.hasMasonryValue = true
    this.masonry = new MiniMasonry({
      container: this.element,
      baseWidth: 350,
      gutter: 8,
      gutterX: 8,
      gutterY: 8,
      ultimateGutter: 8,
      surroundingGutter: false
    })
    // We set "height: 150vh" on the masonry container during initialization to ensure the browser has scrollbars.
    // This helps the masonry javascript library calculate the correct available space on the page.
    // We remove this 150vh after the masonry has initialized.
    this.scrollbarFixValue = true
  }
}
