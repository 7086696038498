import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['template', 'container']

  showMessage (message) {
    const messageTemplate = this.templateTarget.content.children[0].cloneNode(true)
    messageTemplate.querySelector('[data-text]').innerText = message
    this.containerTarget.appendChild(messageTemplate)
    // Remove the message from the DOM after the CSS fade out animation has finished
    setTimeout(() => {
      this.containerTarget.removeChild(messageTemplate)
    }, 5000)
  }
}
