import { Controller } from 'stimulus'
import { userTextInputToUrlSlug, getItemSearchPath } from 'lib/linkHelpers'
import { googleAnalyticsEvent } from 'lib/googleAnalytics'
import throttle from 'lodash/throttle'
import * as Turbo from '@hotwired/turbo'

export default class extends Controller {
  static targets = ['input', 'filter']
  static values = {
    visible: Boolean,
    filters: Object,
    showClearButton: Boolean
  }

  initialize () {
    this.resize = throttle(this.resize, 50)
  }

  connect () {
    // We don't want to do this expensive work on the turbo in-memory cached preview (the first time connect() runs).
    // We wait a few more milliseconds for the network request to load before doing this (the second time connect() runs).
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    this.filtersValue = {}
    this.setFilterLabels()
    this.resize()
  }

  setFilterLabels () {
    if (this.hasFilterTarget) {
      for (const filter of this.filterTargets) {
        const filterType = filter.getAttribute('data-filter')
        const filterDefault = filter.getAttribute('data-default')
        const filterValue = filter.getAttribute('data-value')
        const filterLabel = filter.querySelector('[data-label]')
        const currentElement = filter.querySelector('.search-form__filter-option--current')
        if (currentElement) {
          currentElement.classList.remove('search-form__filter-option--current')
        }
        const newElementToHighlight = filter.querySelector(`.search-form__filter-option[data-type="${filterValue || filterDefault}"]`)
        if (newElementToHighlight) {
          newElementToHighlight.classList.add('search-form__filter-option--current')
          filterLabel.innerHTML = newElementToHighlight.innerHTML
        }
        if (filterValue) {
          this.filtersValue = { ...this.filtersValue, [filterType]: filterValue }
        }
        if (filterValue === filterDefault) {
          // We cannot use delete in stimulus, need to do the assignment on values, so we do a tricky omit first:
          // delete this.filtersValue[filterType]
          const { [filterType]: omitted, ...filtersWithDefaultRemoved } = this.filtersValue
          this.filtersValue = filtersWithDefaultRemoved
        }
      }
    }
  }

  filterChange (event) {
    event.preventDefault()
    this.showClearButtonValue = false
    const filterWrapper = event.target.closest('[data-filter]')
    const filterType = filterWrapper.getAttribute('data-filter')
    const newFilterValue = event.target.closest('[data-type]').getAttribute('data-type')
    filterWrapper.setAttribute('data-value', newFilterValue)
    this.setFilterLabels()
    if (filterType !== 'itemType' && this.inputTarget.value.length > 0) {
      // If the user has a search term entered already and they change a filter we want to automatically submit the form.
      this.search()
    } else {
      // If the user doesn't have a search term yet, we just put the focus into the search box so they can type something.
      this.inputTarget.focus()
    }
    return false
  }

  keyUp () {
    this.showClearButtonValue = false

    if (this.filtersValue.itemType === 'illustrations') {
      this.resetFiltersToDefaultValues()
      this.removeIllustrationFilters()
    }
  }

  logFreeTextSearchGaEvent ({ searchTerm, callback }) {
    googleAnalyticsEvent({
      eventAction: searchTerm,
      eventCategory: 'Search Query (free text)',
      eventLabel: this.filtersValue.itemType,
      callback
    })
  }

  async search (event) {
    event?.preventDefault()

    const searchUrlSlug = userTextInputToUrlSlug(this.inputTarget.value)
    if (searchUrlSlug.length > 0) {
      const destinationUrl = getItemSearchPath({ filters: this.filtersValue, searchTermUrlSlug: searchUrlSlug })
      this.logFreeTextSearchGaEvent({
        searchTerm: this.inputTarget.value,
        callback: () => {
          // Navigate to the new search page after the GA event has been logged (or after it times out and its fallback timer kicks in)
          Turbo.visit(destinationUrl)
        }
      })
    }
    return false
  }

  clearSearchTerms () {
    this.inputTarget.value = ''
    this.inputTarget.focus()
    this.showClearButtonValue = false
    this.resetFiltersToDefaultValues()
    this.removeIllustrationFilters()
  }

  resetFiltersToDefaultValues () {
    if (this.hasFilterTarget) {
      for (const filter of this.filterTargets) {
        const filterDefault = filter.getAttribute('data-default')
        if (filterDefault) {
          filter.setAttribute('data-value', filterDefault)
        }
      }
    }
    this.setFilterLabels()
  }

  // We want to remove the Illustration style filter option completely in certain cases:
  // - When the "clear" button is clicked.
  // - When a new search term is entered.
  // This is because we get a fresh batch of styles on each full SSR, and it doesn't make sense to keep the old
  // stale ones around in the DOM while the user is searching for a new keyword.
  removeIllustrationFilters () {
    const illustrationFilter = this.element.querySelector('[data-filter-item-type="illustrations"]')
    if (illustrationFilter) {
      illustrationFilter.remove()
    }
  }

  showSearchForm () {
    this.visibleValue = true
    this.inputTarget.focus()
  }

  resize () {
    const windowWidth = window.innerWidth
    if (windowWidth > 769 && document.querySelector('body').getAttribute('data-header-style') === 'hidden') {
      // We don't want the hidden search bar on mobile right now, it doesn't work too well.
      // So only go into simplified search bar if the "hidden" header attribute is set, and we're above tablet size.
      this.visibleValue = false
    } else {
      this.visibleValue = true
    }
  }
}
