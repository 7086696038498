import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['container', 'content']
  static values = {
    opened: Boolean,
    loading: Boolean
  }

  close () {
    this.hideModal()
    this.emptyModalContents()
  }

  showModal () {
    this.openedValue = true
  }

  hideModal () {
    this.openedValue = false
  }

  emptyModalContents () {
    this.setContentHtml('')
  }

  setContentHtml (html) {
    this.contentTarget.innerHTML = html
  }

  setContentNode (node) {
    this.emptyModalContents()
    this.contentTarget.appendChild(node.cloneNode(true))
  }
}
