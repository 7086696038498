import { Controller } from 'stimulus'

export default class extends Controller {
  openSettings () {
    const cookiebotOnLoadHandler = window.addEventListener('CookiebotOnLoad', () => {
      window.removeEventListener('CookiebotOnLoad', cookiebotOnLoadHandler)
      window.location.reload()
    })
    if (window.Cookiebot) { window.Cookiebot.renew() }
  }
}
